import admin from './rules/admin'
import verificador from './rules/verificador'
import validador from './rules/validador'
import jurado from './rules/jurado'

export default {
    admin, verificador, validador, jurado
}



